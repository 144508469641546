import React from "react";
import ImageCarousel from "./ImageCarousel";
import close from "../../assets/svg/close.svg";
import moment from "moment";
import { useState } from "react";
import Loader from "../Modal/Loader";
import { useAuth0 } from "@auth0/auth0-react";
import language from "../../Json/locale.json";
import useStorage from "../../hooks/useStorage";

const EventDetails = ({
  nextStep,
  closeModal,
  packageDetails,
  packageLoading,
}) => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const [selectedTab, setSelectedTab] = useState(0);
  const [readMore, setReadMore] = useState(false);
  const locale = useStorage();

  const LocationContent = (props) => {
    const { lan, lat } = props;
    let url =
      "https://maps.google.com/maps?q=" +
      lat +
      "," +
      lan +
      "&hl=es;z=14&output=embed";
    return (
      <div className="text-content">
        <iframe src={url}></iframe>
      </div>
    );
  };

  const truncateHtml = (htmlString, maxLength) => {
    const doc = new DOMParser().parseFromString(htmlString, "text/html");
    const body = doc.body;
    let truncatedLength = 0;
    const stack = [body];
    while (stack.length > 0 && truncatedLength < maxLength) {
      const element = stack.pop();
      if (element.nodeType === Node.TEXT_NODE) {
        const remainingLength = maxLength - truncatedLength;
        const text = element.textContent.slice(0, remainingLength);
        truncatedLength += text.length;
        element.textContent = text;
      } else {
        for (let i = element.childNodes.length - 1; i >= 0; i--) {
          const child = element.childNodes[i];
          if (child.nodeType === Node.TEXT_NODE) {
            const remainingLength = maxLength - truncatedLength;
            const text = child.textContent.slice(0, remainingLength);
            truncatedLength += text.length;
            child.textContent = text;
          } else {
            stack.push(child);
          }
        }
      }
    }
    return body.innerHTML;
  };

  const currentDateTime = moment(new Date());
  const startDate = moment(packageDetails?.booking_start_from);
  const endDate = moment(packageDetails?.booking_end_on);

  const isCurrentDateTimeBetween =
    currentDateTime.isSameOrBefore(startDate) ||
    currentDateTime.isSameOrAfter(endDate);
  const disabled = packageDetails?.disabled === "1";
  const notEnabled = packageDetails?.enable === "0";

  const bookingStatusMessage = () => {
    if (currentDateTime.isSameOrBefore(startDate)) {
      return language.bookings_not_started[locale];
    } else if (currentDateTime.isSameOrAfter(endDate)) {
      return language.bookings_ended[locale];
    } else {
      return "";
    }
  };

  return (
    <div>
      <div className="flex flex-col md:flex-row gap-[48px] overflow-hidden">
        <div className="fixed top-0 left-0 right-[24px] z-10 bg-modalBg md:max-h-[90vh] pt-[20px] pb-[10px] block md:hidden w-auto flex justify-end">
          <button
            className="block md:hidden relative opacity-7 h-[36px] min-w-[36px] w-[36px] block bg-[#FFFFFF4D] py-0 rounded-full"
            onClick={closeModal}
          >
            <img
              src={close}
              alt="close"
              className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
            />
          </button>
        </div>
        <div className="relative md:w-7/12 mt-[84px] md:mt-0">
          <ImageCarousel image={packageDetails?.img} />
          {/* <img src={events} alt="" className="w-[557px] h-[432px]" /> */}
        </div>
        <div
          className={`md:w-5/12 pb-[6rem] md:pb-0 ${
            !packageDetails && "flex justify-center items-center"
          }`}
        >
          {!packageLoading ? (
            <>
              {packageDetails ? (
                <>
                  <h2 className="text-[20px] md:text-[32px] text-white mb-[36px]">
                    {packageDetails?.package_name}
                  </h2>
                  <p className="text-white text-[16px]">
                    {readMore ? (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: packageDetails?.description,
                        }}
                      />
                    ) : (
                      <p
                        className="truncate"
                        dangerouslySetInnerHTML={{
                          __html: packageDetails?.description
                            ? packageDetails.description
                            : "",
                        }}
                      />
                    )}
                    {packageDetails?.description?.replace(/<\/?[^>]+(>|$)/g, "")
                      .length > 350 && (
                      <span
                        className="opacity-50 cursor-pointer"
                        onClick={() => setReadMore(!readMore)}
                      >
                        {readMore
                          ? language.read_less[locale]
                          : language.read_more[locale]}
                      </span>
                    )}{" "}
                    <br />
                    <br />
                    {packageDetails?.start_date && packageDetails?.end_date && (
                      <>
                        {language.date[locale]}:{" "}
                        <span className="opacity-50">
                          {moment(
                            packageDetails?.start_date,
                            "YYYY-MM-DD"
                          ).format("ll")}{" "}
                          -{" "}
                          {moment(
                            packageDetails?.end_date,
                            "YYYY-MM-DD"
                          ).format("ll")}
                        </span>{" "}
                      </>
                    )}
                    <br />
                    {/* Ages: <span className="opacity-50">12+</span> <br /> */}
                    {packageDetails?.location && (
                      <>
                        {language.location[locale]}:{" "}
                        {/* <span className="opacity-50 underline">View on map</span>{" "} */}
                        <span className="opacity-50">
                          {packageDetails?.location}
                        </span>
                        <br />
                      </>
                    )}

                       {packageDetails?.time && (
                      <>
                        {language.time[locale]}:{" "}
                        {/* <span className="opacity-50 underline">View on map</span>{" "} */}
                        <span className="opacity-50">
                          {packageDetails?.time}
                        </span>
                        <br />
                      </>
                    )}



                       {packageDetails?.age && (
                      <>
                        {language.age[locale]}:{" "}
                        {/* <span className="opacity-50 underline">View on map</span>{" "} */}
                        <span className="opacity-50">
                          {packageDetails?.age}
                        </span>
                        <br />
                      </>
                    )}


                    {packageDetails?.starts_from &&
                      packageDetails?.summer_camp !== "1" && (
                        <>
                          {language.price[locale]}:{" "}
                          <span className="opacity-50">
                            {language.starts_from[locale]} AED{" "}
                            {packageDetails?.starts_from}{" "}
                          </span>
                          <br />
                        </>
                      )}
                    {/* {packageDetails?.price &&
                      packageDetails?.summer_camp !== "1" && (
                        <>
                          {language.adult[locale]}:{" "}
                          <span className="opacity-50">
                            AED {packageDetails?.price}{" "}
                          </span>
                          <br />
                        </>
                      )} */}
                    {/* {packageDetails?.child_price &&
                      packageDetails?.url_slug !== "ATATW2023-wk1" && (
                        <>
                          {language.children[locale]}:{" "}
                          <span className="opacity-50">
                            AED {packageDetails?.child_price}{" "}
                          </span>
                        </>
                      )} */}
                    {packageDetails?.note && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: packageDetails?.note,
                        }}
                      />
                    )}
                  </p>
                  {packageDetails && (
                    <>
                      {!isAuthenticated ? (
                        <button
                          className="flex justify-center align-center text-white text-[16px] px-[20px] py-[6px] w-fit gradient-border relative my-[50px]"
                          onClick={loginWithRedirect}
                        >
                          {language.login[locale]}
                        </button>
                      ) : (
                        <>
                          {!packageDetails?.tags?.includes(
                            "bookclub-temp-changed-to-enable-book-button-for-this-tag-too"
                          ) && (
                            <button
                              className="flex justify-center align-center text-white text-[16px] px-[20px] py-[6px] w-fit gradient-border relative mt-[50px] mb-[20px]"
                              onClick={nextStep}
                              disabled={
                                disabled ||
                                notEnabled ||
                                isCurrentDateTimeBetween
                              }
                            >
                              {language.book[locale]}
                            </button>
                          )}
                        </>
                      )}
                      <p className="text-white text-[16px]">
                        {disabled || notEnabled
                          ? language.bookings_disabled[locale]
                          : bookingStatusMessage()}
                      </p>
                    </>
                  )}
                </>
              ) : (
                <p className="text-white text-[18px]">
                  {language.event_not_found[locale]}
                </p>
              )}
            </>
          ) : (
            <div
              role="status"
              className="flex justify-center w-full h-full items-center"
            >
              <svg
                aria-hidden="true"
                class="me-2 w-8 h-8 text-[#616161] animate-spin fill-white"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <span class="sr-only">Loading...</span>
            </div>
          )}
        </div>
        <button
          className="hidden md:block relative opacity-7 h-[36px] min-w-[36px] w-[36px] block bg-[#FFFFFF4D] py-0 rounded-full"
          onClick={closeModal}
        >
          <img
            src={close}
            alt="close"
            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
          />
        </button>
      </div>
      {/* <div className="mt-[32px] md:w-6/12">
        <ul className="flex text-[20px] gap-[64px] mb-[36px]">
          <li
            className={`text-white cursor-pointer pb-[8px] ${
              selectedTab === 0 ? "border-b-[1px] border-white" : "opacity-50"
            }`}
            onClick={() => setSelectedTab(0)}
          >
            Information
          </li>
          <li
            className={`text-white cursor-pointer pb-[8px] ${
              selectedTab === 1 ? "border-b-[1px] border-white" : "opacity-50"
            }`}
            onClick={() => setSelectedTab(1)}
          >
            Location
          </li>
        </ul>
        {selectedTab === 0 ? (
          <p className="text-white text-[16px]">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vitae
            scelerisque neque. Sed ac mollis erat. Duis quis porttitor tortor.
            Nulla at augue ac lorem malesuada rutrum. Morbi tincidunt auctor
            dapibus. Suspendisse luctus erat non aliquet imperdiet. Nulla eget
            porta arcu, vitae mattis ante. In ac dui sem. Pellentesque
            porttitor, nunc eget lobortis faucibus, mi justo tincidunt ante,
            placerat feugiat erat turpis vel tortor.
          </p>
        ) : (
          <p className="text-white text-[16px]">
            <LocationContent
              lan={packageDetails?.lan}
              lat={packageDetails?.lat}
            />
          </p>
        )}
      </div> */}
    </div>
  );
};

export default EventDetails;
